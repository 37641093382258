@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom Fonts */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

@layer base {
  p {
    @apply font-poppins;
    @apply md:text-lg text-base;
  }
  h1,
  h2,
  h3 {
    @apply font-montserrat font-semibold tracking-wide;
  }
  h1 {
    @apply text-3xl md:text-4xl;
  }
  h2 {
    @apply text-2xl md:text-3xl;
  }
  h3 {
    @apply text-xl lg:text-2xl;
  }
  h4 {
    @apply lg:text-xl text-lg;
  }
}

/* Custom Classes Here */

.init-navbar {
  background-color: rgba(0, 0, 0, 0);
  @apply py-3;
  @apply md:py-4;
  @apply lg:py-6;
}

.scrolled-navbar {
  @apply bg-white;
  @apply py-1;
  @apply md:py-1;
  @apply border-gray-200 border-b-2;
}

.text_social {
  font-size: 8px;
}

/* Contact Modal classes */

.modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @apply bg-gray-200 bg-opacity-70;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999999;
  box-sizing: border-box;
}

.modal.modal-fade {
  animation: fade-in 1s 1 linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.modal > .modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal > .modal-close {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #5e5e5e;
  cursor: pointer;
  font-size: 1.25em;
  padding: 7px;
  background: rgba(255, 255, 255, 0.749);
  border: 1px solid #c3c0c0;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
}

.modal > .modal-close:hover {
  background: rgba(255, 255, 255, 0.989);
}

.modal > .modal-body {
  z-index: 2;
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}
